import React, { useRef } from 'react'
import './header.scss';
import seta from './../../assets/seta-button.svg';


const Header = () => {

    const headerRef = useRef();
    
    return (
        <div className='header' id='header' ref={headerRef}>
            <div className='header__content'>
                <h1>Estabeleça um Ambiente de Trabalho Seguro</h1>
                <p>A Oston Engenharia tem como objetivo auxiliar a sua empresa para que ela tenha um ambiente de trabalho seguro e de acordo com as normas regulamentadoras.</p>
                <div className='header__button'>
                    <a href="#benefits">
                        <img src={seta} alt="logo" /> 
                    </a>
                </div>
            </div>
            
        </div>
    )
}

export default Header