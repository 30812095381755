import "./benefits.scss";
import {  useRef } from "react";

const Benefits = () => {
    const benefitsRef = useRef();

    return (
        <div className="benefits" id="benefits" ref={benefitsRef}>
            <div className="benefits__header">
                <h5>BENEFÍCIOS</h5>
                <h2>Detalhes que elevam as empresas</h2>
            </div>
            <div className="benefits__main">
                <div>
                    <div className="benefit">
                        <h3>Redução de Acidentes e Lesões</h3>
                        <p>A implementação de medidas de segurança, avaliação de riscos e o desenvolvimento de procedimentos adequados ajudam a reduzir a ocorrência de acidentes e lesões no ambiente de trabalho.</p>
                    </div>
                    <div className="benefit">
                        <h3>Redução de Custos</h3>
                        <p>A prevenção de acidentes e doenças ocupacionais reduz os custos associados a tratamentos médicos, compensações trabalhistas e paralisações devido a acidentes.</p>
                    </div>
                </div>
                <div>
                    <div className="benefit">
                        <h3>Conformidade com as Normas e Regulamentações</h3>
                        <p>A engenharia de segurança do trabalho ajuda as empresas a cumprirem a legislação específica em relação a saúde e segurança ocupacional, evitando assim multas e sanções legais.</p>
                    </div>
                    <div className="benefit">
                        <h3>Valorização da Imagem da Empresa</h3>
                        <p>Empresas que investem em segurança do trabalho demonstram comprometimento com o bem-estar dos funcionários, impactando diretamente na imagem da empresa perante clientes, investidores e a comunidade em geral.</p>
                    </div>
                </div>
            </div>
            <button className="btn">
                <a href="#contact">Entrar em Contato</a>
            </button>
        </div>
    );
};

export default Benefits;
