import React from 'react'
import logo from './../../assets/logo.svg'
import './navbar.css'

const Header = () => {
    return (
        <div className='navbar'>
            <div className='navbar__logo'>
                <a href="#header">
                    <img src={logo} alt="" />
                </a>
            </div>

            <menu className='navbar__menu'>
                <li><a href="#header">Início</a></li>
                <li><a href="#benefits">Benefícios</a></li>
                <li><a href="#services">Serviços</a></li>
                <li><a href="#footer">Contato</a></li>
            </menu>
        </div>
    )
}

export default Header