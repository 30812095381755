import React from 'react'
import './services.scss'
import Service from './Service/Service'

const Services = () => {
    const estudoDeAreaClassificada = {
        title: 'Estudo de Área Classificada',
        description: 'O estudo de área classificada tem como objetivo avaliar o potencial risco de formação de atmosfera explosiva (gases, líquidos inflamáveis, poeira, fibras, etc) em um determinado local.'
    };
    const licenciamentoEdificacao = {
        title: 'Licenciamento de Edificação - AVCB / CLCB',
        description: 'O AVCB é um documento emitido pelo Corpo de Bombeiros onde certifica e valida a segurança contra o risco de incêndios em edificações comerciais e residenciais. É um documento obrigatório nas instalações. Para o CLCB aplica-se a mesma finalidade no combate contra incêndio, mas possui parâmetros para locais mais simples conforme requisitos de regularização.'
    };
    const analisePreliminarRisco = {
        title: 'Análise Preliminar de Risco - APR',
        description: 'A Análise Preliminar de Riscos (APR) consiste no estudo inicial de uma determinada atividade, com a finalidade de identificar previamente os riscos presentes na mesma e avaliar as medidas de controle e/ou mitigação a serem adotadas em sua fase de execução.'
    };
    const programaGerenciamentoResiduos = {
        title: 'Programa de Gerenciamento de Resíduos de Serviços de Saúde',
        description: 'O PGRSS (Plano de Gerenciamento de Resíduos do Serviço de Saúde) é um conjunto de documentos que apresentam ações exigidas pelos órgãos ambientais e vigilância sanitária por parte dos geradores de resíduo de qualquer estabelecimento ligado a área da saúde.'
    };

    return (
        <div className='services' id='services'>
            <h2>Serviços</h2>
            <Service 
                title={estudoDeAreaClassificada.title} 
                description={estudoDeAreaClassificada.description}
            />
            <Service 
                title={licenciamentoEdificacao.title} 
                description={licenciamentoEdificacao.description}
            />
            <Service 
                title={analisePreliminarRisco.title} 
                description={analisePreliminarRisco.description}
            />
            <Service 
                title={programaGerenciamentoResiduos.title} 
                description={programaGerenciamentoResiduos.description}
                border='none'
            />
        </div>
    )
}

export default Services