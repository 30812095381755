import { useState } from "react";
import './service.scss';
import seta from './../../../assets/seta.svg'

function Service({ title, description, border = 'solid rgb(99, 99, 99) 0.2px'}) {
    const [showDescription, setShowDescription] = useState(false);
    function handleClick() {
        setShowDescription(!showDescription);
    }

    return (
        <button className='service' onClick={handleClick} style={{borderBottom: border}}>
            <div>
                <h4 className='service__title' >
                    {title}
                </h4>   
                <img src={seta} alt="" />
            </div>
            {showDescription && (
                <div className='service__explanation'>
                    <p className='service__explanation__description'>
                        {description}
                    </p>
                </div>) 
            }
        </button>
    )
}

export default Service