import React from 'react'
import logo from './../../assets/logoOston.png'
import './footer.scss'

const Footer = () => {
  return (
    <div id='footer' className='footer'>
        <div className='footer__contact'>
            <div className='logo'>
                <img src={logo} alt="" srcset="" />
            </div>
            <div className='info'>
                <h4>Contato</h4>
                <div className='info__options'>
                    <p>Email: ostonengenharia@gmail.com</p>
                    <p>Whatsapp: +55 (19) 99983-2991</p>
                </div>
            </div>
            {/* <div className='social-media'>
                <h4>Redes Sociais</h4>
                <div className='social-media__options'>
                    <p>Instagram</p>
                    <p>Linkedin</p>
                </div>
            </div> */}
        </div>
    </div>
  )
}

export default Footer