import Navbar from './components/Navbar/Navbar'
import Header from './components/Header/Header'
import Benefits from './components/Benefits/Benefits'
import Services from './components/Services/Services'
import Footer from './components/Footer/Footer'

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Benefits />
      <Services />
      <Footer />
    </div>
  );
}

export default App;
